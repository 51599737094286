import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticateService, AuthUser } from '../authentication/config/authenticate.service';


export interface MainNavListOption {
  option: string;
  url: string;
}

@Component({
  selector: 'medusa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent {
  authUser$: Observable<AuthUser | null>;

  constructor(private authService: AuthenticateService, private router: Router) {
    this.authUser$ = this.authService.authUser$;
  }

  logo: MainNavListOption = {
    option: '/assets/logo/lumen_logo_x1.png',
    url: '/home'
  }


  logout() {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }
}