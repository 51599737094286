export type LogLevel = 'debug' | 'info' | 'warn' | 'error';

export type ZoneMap = Map<string, any>;

export function getZone(zones: ZoneMap, key: string): any {
    return zones.get(key);
}
export interface IEnvironment {
    production: boolean;
    enableDebugTools: boolean;
    logLevel: LogLevel;
    apiUrl: string;
    apiHost: string;
    baseUrl: string;
    reputationLookupUrl: string;
    amplifyConfig: any;
    regionDomains: any;
    zones: ZoneMap;
    regions: { label: string; value: string }[];
    routesRegister: { [key: string]: any }
}