import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

export interface LoginAction {
  action: 'LOGIN' | 'FORGOT_PASSWORD' | 'REGION_CHANGED';
  payload: any;
}

@Component({
  selector: 'medusa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private destroyer$ = new Subject();

  @Input() regionOptions: { label: string, value: string }[] = [];
  @Input() defaultRegion: string | null = 'us-east-1';
  @Output() actionHandler: EventEmitter<LoginAction> = new EventEmitter();

  form: FormGroup | null | any = null;

  constructor(
    private fb: FormBuilder
  ) {

  }

  ngOnInit(): void {
    this.form = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      region: [this.defaultRegion, [Validators.required]]
    });

    // emit on region changed
    this.form.controls['region'].valueChanges.pipe(takeUntil(this.destroyer$)).subscribe(
      (region: any) => this.actionHandler.emit({ action: 'REGION_CHANGED', payload: region })
    );
  }

  login(): void {
    if (this.form?.valid) {
      this.actionHandler.emit({ action: 'LOGIN', payload: this.form.value });
    }
  }

  forgotPassword(): void {
    this.actionHandler.emit({ action: 'FORGOT_PASSWORD', payload: null });
  }

  ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }
}
