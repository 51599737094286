import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginAction } from '@medusa/ui';
import { FORGOT_PASSWORD } from 'apps/apolo/src/environments';
import { Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { EnvironmentService } from '../../../../environments/environment.service';
import { AuthenticateService } from '../../config/authenticate.service';
// import { EnvironmentService } from '@apolo/app/environments/environment.service';

@Component({
  selector: 'medusa-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  error$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  regionOptions: { label: string; value: string }[] = [];

  constructor(private envService: EnvironmentService, private router: Router, private authService: AuthenticateService) { }

  ngOnInit(): void {
    this.regionOptions = this.envService.regions;
  }

  private handleRegionChanged(payload: string): void {
    this.envService.updateRegion(payload);
  }

  private handleForgotPassword(payload: any): void {
    const fgr = this.envService.routesRegister[FORGOT_PASSWORD];
    window.open(fgr, '_blank')
  }

  private handleLogin(credentials: any): void {
    this.loading$.next(true);
    this.error$.next(null);
    Auth.signIn({ username: credentials.username, password: credentials.password })
      .then(response => {
        this.authService.setAuthUser(response);
        this.router.navigate(['/search'])
      })
      .catch(error => {
        if (error.code === 'UserNotFoundException') {
          this.error$.next('No account found under the provided username');
        } else if (error.code === 'NotAuthorizedException') {
          this.error$.next('Invalid username and/or password.');
        }
      })
      .finally(() => this.loading$.next(false));
  }

  actionHandler(evt: LoginAction): void {
    console.log('handling event', evt);
    switch (evt.action) {
      case 'REGION_CHANGED':
        this.handleRegionChanged(evt.payload);
        break;
      case 'FORGOT_PASSWORD':
        this.handleForgotPassword(evt.payload);
        break;
      case 'LOGIN':
        this.handleLogin(evt.payload);
        break;
      default:
        break;
    }
  }
}
