<div class="table-container">
  <div class="table-menu-container mat-elevation-z3">
    <button mat-icon-button color="primary" (click)="exportTable()">
      <mat-icon>file_download</mat-icon>
    </button>
  </div>
  <table
    #table
    [ngStyle]="config.style"
    [ngClass]="config.classes"
    mat-table
    [dataSource]="dataSource"
    [id]="tableId"
  >
    <ng-container [matColumnDef]="header.name" *ngFor="let header of headers">
      <th mat-header-cell *matHeaderCellDef>{{ header.label }}</th>
      <td mat-cell *matCellDef="let element">
        {{
          header.extractor ? header.extractor(element) : element[header.name]
        }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
