import * as XLSX from 'xlsx';

const getFilename = (name: string) => {
  let timeSpan = new Date().toISOString();
  let sheetName = name || "ExportResult";
  let filename = `${sheetName}-${timeSpan}`;
  return {
    sheetName,
    filename
  };
};


export class TableUtil {
  static exportTableToExcel(tableId: string, name?: string): void {
    let { sheetName, filename } = getFilename(name ?? '');
    let targetTableElm = document.getElementById(tableId);
    let wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{
      sheet: sheetName
    });
    XLSX.writeFile(wb, `${filename}.xlsx`);
  }

  static exportArrayToExcel(arr: any[], name?: string) {
    let { sheetName, filename } = getFilename(name ?? '');

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(arr);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${filename}.xlsx`);
  }
}