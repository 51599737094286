import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDictionary, IInputConfig } from '@medusa/ui';
import { EnvironmentService } from '../environments/environment.service';
import { AuthenticateService } from './authentication/config/authenticate.service';
import { HttpService } from './core/_services/http.service';

export interface MainNavListOption {
  option: string;
  url: string;
  display?: string;

}

@Component({
  selector: 'medusa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent {
  title = 'apolo';
  config: IInputConfig = {
    readonly: false,
    type: 'text',
    appearance: 'outline',
    display: true,
    classes: '',
    styles: {}
  }
  form: FormGroup = new FormGroup({
    username: new FormControl('Hermann kon', [Validators.required])
  })

  customStyles = {
    "backgroundColor": "red",
    "color": "white"
  }

  

  ipTableConfig = {
    style: {} as IDictionary,
    classes: ['mat-elevation-z8']
  }

  devTestLink = 'http://localhost:3000/ddos_search';

  constructor(
    public envService: EnvironmentService,
    public authService: AuthenticateService,
    public httpService: HttpService
  ) {
    this.form.valueChanges.subscribe(val => {
      console.log('the search params: ', val);

    })
  }
}
