import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModuleModule, UiModule } from '@medusa/ui';
import { IpAndDomainReputationOverviewComponent } from './ip-and-domain-reputation-overview/ip-and-domain-reputation-overview.component';
import { ReputationCenterRoutingModule } from './reputation-center-routing.module';




@NgModule({
  declarations:
    [
      IpAndDomainReputationOverviewComponent,
    ],
  imports: [
    CommonModule,
    UiModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModuleModule,
    ReputationCenterRoutingModule
  ],
  exports: [IpAndDomainReputationOverviewComponent],
})
export class ReputationCenterModule { }