<section class="login-component">
  <form [formGroup]="form" (submit)="login()">
    <h2>Login</h2>
    <!-- username -->
    <mat-form-field appearance="outline">
      <mat-label>Username or Email</mat-label>
      <input matInput formControlName="username" />
    </mat-form-field>

    <!-- Password -->
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput formControlName="password" type="password" />
    </mat-form-field>

    <!-- region selector -->
    <mat-form-field appearance="outline">
      <mat-label>Region</mat-label>
      <mat-select formControlName="region">
        <mat-option
          [value]="option.value"
          *ngFor="let option of regionOptions"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <div class="actions">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="form.invalid"
      >
        Sign In
      </button>
    </div>
    <div class="forgot-password">
      <a (click)="forgotPassword()">Forgot Password</a>
    </div>
  </form>
</section>
