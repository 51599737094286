import { Component, EventEmitter, Input, Output } from '@angular/core';

export type ButtonType = 'raised' | 'stroked' | 'flat' | 'icon' | 'fab' | 'miniFab';

export enum ColorConfig {
  warn = 'warn',
  primary = 'primary',
  accent = 'secondary'
}
export interface IButtonConfig {
  type: ButtonType,
  color: ColorConfig
}
@Component({
  selector: 'medusa-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() label: string | undefined;
  @Input() customStyles: { [key: string]: string } | undefined;
  @Input() config: IButtonConfig = {} as IButtonConfig;
  @Input() disabled: boolean = false;
 
  @Output() clickHandler = new EventEmitter();

  buttonClicked(): void {
    this.clickHandler.emit();
  }
}