import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, filter, interval, Subject, switchMap, timer } from 'rxjs';

export interface ICarouselConfig {
  style: any;
  classes: string | string[];
  imageStyle: any;
  imageClasses: string | string[];
  leftControl: boolean;
  rightControl: boolean;
  ellipses: boolean;
}

export interface ICarouselImage {
  url: string;
  label: string
}

@Component({
  selector: 'medusa-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit, OnDestroy {

  @Input() selected: string = '';
  @Input() images: string[] = [];
  @Input() config: ICarouselConfig = {
    leftControl: true,
    rightControl: true,
    ellipses: true
  } as ICarouselConfig;
  @Input() autoPlayInterval = 0;

  currentIndex = 0;
  currentImage$: BehaviorSubject<string> = new BehaviorSubject('');
  autoPlay$ = new Subject();
  destroyer$ = new Subject();

  constructor() { }

  ngOnInit(): void {
    this.currentImage$.next(this.selected || this.images[0] || '');


    this.autoPlay$.pipe(
      filter(() => this.autoPlayInterval > 0),
      switchMap(() => interval(this.autoPlayInterval * 1000))
    ).subscribe(_ => {
      console.log('the autoplay');

      this.next();
    })

    this.autoPlay$.next(this.autoPlayInterval);

  }

  prev() {
    if (this.nImages <= 0) {
      return;
    }

    if (this.currentIndex === 0) {
      this.currentIndex = this.images.length;
    }

    --this.currentIndex;
    this.nextImage();
  }

  next() {
    if (this.nImages <= 0) {
      return;
    }

    const last = (this.images.length - 1);
    if (this.currentIndex === last) {
      this.currentIndex = -1;
    }

    ++this.currentIndex;
    this.nextImage();
  }

  get nImages(): number {
    return this.images.length;
  }

  nextImage() {

    const absIndex = Math.abs(this.currentIndex)
    const image = this.images[absIndex];

    this.currentImage$.next(image)
  }

  processEllipse(payload: number) {
    this.currentIndex = payload;
    this.nextImage();
  }

  ngOnDestroy(): void {
    this.destroyer$.next(null);
    this.destroyer$.complete();
  }
}
