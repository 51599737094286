import { Injectable } from "@angular/core";
import { Auth } from "aws-amplify";
import { BehaviorSubject, Observable } from "rxjs";

export interface AuthUser {
    email: string;
    email_verified: boolean;
    family_name: string;
    gender: string;
    given_name: string;
    profile: string;
    sub: string;
    updated_at: string;
    username: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthenticateService {
    private userSubject: BehaviorSubject<AuthUser | null> = new BehaviorSubject<AuthUser | null>(null);

    authUser$: Observable<AuthUser | null> = this.userSubject.asObservable();

    constructor() {
        Auth.currentAuthenticatedUser()
            .then(user => this.setAuthUser(user))
            .catch(() => this.setAuthUser(null))

    }

    setAuthUser(userResponse: any): void {
        if (userResponse) {
            const { username, attributes } = userResponse;
            this.userSubject.next({
                ...attributes,
                username
            });
        } else {
            this.userSubject.next(null);
        }
    }

    public logout() {
        Auth.signOut();
        this.userSubject.next(null)
        localStorage.clear();
    }
}
