<section class="login-container">
  <div class="login-loader">
    <medusa-loader [display]="!!(loading$ | async)" [color]="'primary'" [type]="'bar'"></medusa-loader>
  </div>
  <div class="login-wrapper mat-elevation-z3">
    <!-- Error display -->
    <div class="error" *ngIf="error$ | async">
      <mat-icon>error</mat-icon>
      <p>{{ error$ | async }}</p>
    </div>
    
    <medusa-login
      [regionOptions]="regionOptions"
      (actionHandler)="actionHandler($event)"
    ></medusa-login>
  </div>
</section>
