<mat-button-toggle-group
  (change)="writeValue($event.value)"
  [value]="val"
  [multiple]="multi"
>
  <mat-button-toggle
    *ngFor="let button of buttons"
    [value]="button.value"
    [ngStyle]="computeBtnStyle(button)"
  >
    <mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon>
    {{ button.friendly }}
  </mat-button-toggle>
</mat-button-toggle-group>
