import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { NxWelcomeComponent } from './nx-welcome.component';
import { RouterModule } from '@angular/router';
import { MaterialModuleModule, UiModule } from '@medusa/ui';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { ReputationCenterModule } from './reputation-center/reputation-center.module';
import { AppRoutingModule } from './app.routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderModule } from './header/header.module';
import { AuthenticationRoutingModule } from './authentication/authentication.routing.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { GravatarModule } from 'ngx-gravatar';


@NgModule({
  declarations: [
    AppComponent,
    NxWelcomeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    HeaderModule,
    ReputationCenterModule,
    AuthenticationRoutingModule,
    AuthenticationModule,
    RouterModule.forRoot([], { initialNavigation: 'enabledBlocking' }),
    GravatarModule,
    MaterialModuleModule,
    MatButtonModule,
    AppRoutingModule
  ],

  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
