<ng-container [ngSwitch]="config.type">
  <ng-container *ngSwitchCase="'raised'">
    <button mat-raised-button (click)="buttonClicked()" [color]="config.color" [disabled]="disabled">
      {{ label || 'Button' }}
    </button>
  </ng-container>
  
  <ng-container *ngSwitchCase="'stroked'">
    <button mat-stroked-button (click)="buttonClicked()" [color]="config.color">
      {{ label || 'Button' }}
    </button>
  </ng-container>
  
  <ng-container *ngSwitchCase="'flat'">
    <button mat-flat-button (click)="buttonClicked()" [color]="config.color">
      {{ label || 'Button' }}
    </button>
  </ng-container>
  
  <ng-container *ngSwitchCase="'icon'">
    <button mat-icon-button (click)="buttonClicked()" [color]="config.color">
      {{ label || 'Button' }}
    </button>
  </ng-container>
  
  <ng-container *ngSwitchCase="'fab'">
    <button mat-fab (click)="buttonClicked()" [color]="config.color">
      {{ label || 'Button' }}
    </button>
  </ng-container>
  
  <ng-container *ngSwitchCase="'miniFab'">
    <button mat-mini-fab (click)="buttonClicked()" [color]="config.color">
      {{ label || 'Button' }}
    </button>
  </ng-container>
  
  <ng-container *ngSwitchDefault>
    <button mat-button (click)="buttonClicked()" [color]="config.color">
      {{ label || 'Button' }}
    </button> 
  </ng-container>
</ng-container>



