import { Component, Input, OnInit } from '@angular/core';

export interface IAlertConfig {
  type: 'error' | 'warn' | 'info';
  ngStyle: any;
}
@Component({
  selector: 'medusa-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {

  @Input() message: string = '';
  @Input() config: IAlertConfig = { } as IAlertConfig;
  
  medusaAlert: any;


  constructor() { }

  ngOnInit(): void { }
}
