import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { timer } from 'rxjs';

export interface IInputConfig {
  readonly: boolean;
  type: string;
  display: boolean;
  appearance: 'fill' | 'outline';
  classes: string | string[];
  styles: { [key: string]: any };
}

@Component({
  selector: 'medusa-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFieldComponent),
      multi: true
    }
  ]
})
export class InputFieldComponent implements ControlValueAccessor {

  /**
  * The registered callback function called when a change or input event occurs on the input
  * element.
  *
  */
  onChange = (_: any) => { };

  /**
   * The registered callback function called when a blur event occurs on the input element.
   *
   */
  onTouched = () => { };

  disabled = false;
  touched = false;
  val = '';

  @Input() config: IInputConfig = { display: true } as IInputConfig;
  @Input() label: string = '';
  @Input() icon: boolean | Object = false;

  @Output() events: EventEmitter<{ type: string, payload: any }> = new EventEmitter();

  set value(val: string) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.writeValue(val);
      this.onChange(val);
      this.onTouched();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleKeyUp(payload: any) {
    this.writeValue(payload.target.value)
  }
}