import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { IpAndDomainReputationOverviewComponent } from './ip-and-domain-reputation-overview/ip-and-domain-reputation-overview.component';



const routes: Routes = [
  {
    path: 'search',
    canActivate: [AuthGuard],
    children: [
      {
        path: '', component: IpAndDomainReputationOverviewComponent
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ReputationCenterRoutingModule { }