import { Component, EventEmitter, Input, Output } from '@angular/core';



@Component({
    selector: 'medusa-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})

export class FooterComponent {
    mainNavListOptions: any[] =  [
        {
        option: 'Software',
        url: '/software'
        },
        {
          option: 'Reputation Center',
          url: '/software'
        },
        {
          option: 'Vulnerability Information',
          url: '/software'
        },
        {
          option: 'Microsoft Advisory Snort Rules',
          url: '/software'
        },
        {
            option: 'Software',
            url: '/software'
        },
        {
            option: 'Reputation Center',
            url: '/software'
        },
        {
            option: 'Vulnerability Information',
            url: '/software'
        },
        {
            option: 'Microsoft Advisory Snort Rules',
            url: '/software'
        }
    
    ]

}