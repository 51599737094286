<form [formGroup]="form">
  <section class="topHalf">
    <div class="searchField">
      <section class="search">
        <medusa-input-field #searchField formControlName="searchValue" [config]="config" label="Reputation Lookup">
        </medusa-input-field>

        <div class="searchButton">
          <medusa-button (clickHandler)="searchbuttonClicked()" [config]="searchBtnConfig" label="SEARCH"
            [disabled]="form.invalid"></medusa-button>
        </div>
      </section>
      <medusa-button-toggle [buttons]="searchControlButtons" formControlName="dateRange" [selectedStyle]="selectedStyle"
        [multi]="false"></medusa-button-toggle>

      <p>Search by IP, domain, or network owner for real-time threat data.</p>
    </div>
  </section>
</form>

<div class="search-loader-pane">
  <div class="search-loader">
    <medusa-loader [display]="searchLoader$ | async" [type]="'spinner'"></medusa-loader>
  </div>
</div>

<div *ngIf="!!(searchResultsError$ | async)">
  {{ searchResultsError$ | async }}
</div>

<div class="search-results-table"
  *ngIf="!!(searchResults$ | async) && (searchResults$ | async)?.length > 0 && !!!(searchLoader$ | async); else noDataFound">
  <medusa-table [config]="tableConfig" [headers]="searchTableHeaders" [data]="searchResults$"></medusa-table>
</div>



<ng-template #noDataFound>
  <div class="no-data-found" *ngIf="(searchResults$ | async)?.length === 0 && !!!(searchLoader$ | async)">
    <h3>
      No data found for search parameters
    </h3>
  </div>
</ng-template>
