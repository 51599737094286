import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModuleModule, UiModule } from '@medusa/ui';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header.component';
import { GravatarModule } from 'ngx-gravatar';


@NgModule({
  declarations: [HeaderComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    BrowserAnimationsModule,
    MaterialModuleModule,
    GravatarModule
  ],

  exports: [HeaderComponent],
})
export class HeaderModule {}
