import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { MaterialModuleModule } from './material-module/material-module.module';
import { TableComponent } from './table/table.component';
import { FooterComponent } from './footer/footer.component';
import { ButtonToggleComponent } from './button-toggle/button-toggle.component';
import { LoaderComponent } from './loader/loader.component';
import { InputFieldComponent } from './input-field/input-field.component';
import { CarouselComponent } from './carousel/carousel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { LoginComponent } from './login/login.component';
import { AlertComponent } from './alert/alert.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModuleModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule
  ],
  declarations: [
    ButtonComponent,
    TableComponent,
    LoaderComponent,
    ButtonToggleComponent,
    InputFieldComponent,
    FooterComponent,
    CarouselComponent,
    CheckboxComponent,
    LoginComponent,
    AlertComponent,
  ],
  exports: [
    ButtonComponent,
    TableComponent,
    ButtonToggleComponent,
    LoaderComponent,
    InputFieldComponent,
    FooterComponent,
    CarouselComponent,
    LoginComponent,
    CheckboxComponent,
  ],
})
export class UiModule { }
