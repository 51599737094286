<section>
    <section class="innerFooter">
        <div class="direction" *ngFor="let item of mainNavListOptions">
            <div>{{item.option}}</div>
        </div>

        <div class="">afdfds</div>

    </section>
    <section style="border: 10px solid #0075c9"></section>
</section>