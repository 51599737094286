import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { IEnvironment } from './environments/ienvironment';
import { Amplify, Auth } from 'aws-amplify/';

const env: IEnvironment = environment;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));


const requestRoute = `https://${window.location.hostname}`;
const region = env?.regionDomains[requestRoute]?.region || 'us-east-1';

localStorage.setItem('region', region);
const config = env.amplifyConfig[region];
console.log(config)
Auth.configure(config);
Amplify.configure(config);