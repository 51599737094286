import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ICheckboxConfig {
  disabled: boolean;
  type: string;
  display: boolean;
  color: string;
  classes: string | string[];
  styles: { [key: string]: any };
}

@Component({
  selector: 'medusa-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {

  /**
  * The registered callback function called when a change or input event occurs on the input
  * element.
  *
  */
   onChange = (_: any) => { };

   /**
    * The registered callback function called when a blur event occurs on the input element.
    *
    */
   onTouched = () => { };
 
   disabled = false;
   touched = false;
   val = false;

  @Input() config: ICheckboxConfig = { display: true } as ICheckboxConfig;
  @Input() label: string = '';

  constructor() { }
  ngOnInit(): void { }

  set value(val: boolean) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.writeValue(val);
      this.onChange(val);
      this.onTouched();
    }
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleChange(payload: any) {
    this.writeValue(payload.checked)
  }
}
