import { Injectable } from '@angular/core';
import { environment } from './environment';
import { getZone, IEnvironment, ZoneMap } from './ienvironment';
import { Amplify, Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService implements IEnvironment {
  amplifyConfig: any = environment.amplifyConfig;

  get production() {
    return environment.production;
  }

  get enableDebugTools() {
    return environment.enableDebugTools;
  }

  get logLevel() {
    return environment.logLevel;
  }

  get apiHost() {
    return environment.apiHost;
  }

  get apiUrl() {
    return environment.apiUrl;
  }

  get reputationLookupUrl() {
    return environment.reputationLookupUrl
  }

  get baseUrl(): string {
    return environment.baseUrl
  }

  regionDomains: any;
  get zones(): ZoneMap {
    return environment.zones;
  }

  get regions(): { label: string; value: string; }[] {
    return environment.regions;
  }


  // for a given key that identifies a zone, return the corresponding zone
  zoneForKey(key: string): any {
    return getZone(this.zones, key)
  }

  // given a zoneKey and an entryKey(like api_url), return the corresponding zone entity/object
  getZoneApiUrl(zoneKey: string, urlKey: string = ''): string {
    const zone = this.zoneForKey(zoneKey);


    const result = zone?.find((elt: any) => {
      return (elt.key === urlKey)
    });

    return result['value'] ?? '';
  }

  updateRegion(region: string): void {
    const config = environment.amplifyConfig[region];
    Auth.configure(config);
    Amplify.configure(config);
    localStorage.setItem('region', region);
  }

  get routesRegister(): any {
    return environment.routesRegister;
  }
}