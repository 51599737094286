import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'medusa-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  constructor() {}

  @Input() display: boolean = true;
  @Input() color: 'primary' | 'warn' | 'accent' = 'primary';
  @Input() type: 'bar' | 'spinner' = 'bar';

  ngOnInit(): void {}
}
