<div class="projector" [ngClass]="config.classes" [ngStyle]="config.style">

    <div class="image-pane">
        <div class="image-and-controls">
            <img [ngStyle]="config.imageStyle" [ngClass]="config.imageClasses" class="carousel-image"
                [src]="currentImage$ | async" alt="">
    
            <ng-container *ngIf="!!config.leftControl">
                <div class="control-pane left-btn mat-elevation-z11">
                    <button (click)="prev()" mat-icon-button color="primary" aria-label="left arrow action">
                        ←
                    </button>
                </div>
            </ng-container>
    
            <ng-container *ngIf="!!config.rightControl">
                <div class="control-pane right-btn">
                    <button (click)="next()" mat-icon-button color="primary" aria-label="right arrow action">
                        →
                    </button>
                </div>
            </ng-container>
        </div>
    </div>



    <div *ngIf="!!config.ellipses" class="ellipses-pane">
        <div class="ellipse" [ngClass]="{'active-ellipse': currentIndex === i}" *ngFor="let elt of images; let i=index"
            (click)="processEllipse(i)"></div>
    </div>
</div>