import { Component, Input, OnInit, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isEqual } from 'lodash';

export interface Button {
  value: string | any;
  friendly: string;
  styles?: { [key: string]: any };
  icon?: string;
}

export interface ButtonToggle {
  buttons: Button[],
  defaultValue: Array<string | any> | string | any;
  multi: boolean
}

@Component({
  selector: 'medusa-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonToggleComponent),
      multi: true
    }
  ]
})
export class ButtonToggleComponent implements ControlValueAccessor, OnInit {
  @Input() buttons: Button[] = [];
  @Input() multi = false;
  @Input() selectedStyle: { [key: string]: any } = {};

  val: any = '';

  onChange: any = () => { }
  onTouch: any = () => { }

  set value(val: any) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  constructor() {

  }



  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  computeBtnStyle(button: Button): { [key: string]: any } {
    let btnStyle = button?.styles ?? {};
    if (!this.multi) {
      if (isEqual(button.value, this.val)) {
        btnStyle = { ...btnStyle, ...(this.selectedStyle || {}) };
      }
    } else {
      if (this.val.includes(button.value)) {
        btnStyle = { ...btnStyle, ...(this.selectedStyle || {}) };
      }
    }
    return btnStyle;
  }

  ngOnInit(): void { }
}
