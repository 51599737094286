import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subject, takeUntil, timer } from 'rxjs';
import { TableUtil } from './table.utils';

export enum EventTypes {
  TableContentChanged = 'tableContentChanged'
}
export interface IEvent {
  type: string;
  payload: any;
}
export interface ITableHeader {
  name: string;
  label: string;
  extractor?: (row: any) => any;
}

export interface IDictionary {
  [key: string]: any
}

export interface ITableConfig {
  classes: string | string[] | IDictionary;
  style: IDictionary;
}


@Component({
  selector: 'medusa-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  private readonly destroyer$ = new Subject();

  @Input() headers: ITableHeader[] = [];
  @Input() data: BehaviorSubject<any> = new BehaviorSubject([]);
  @Input() config: ITableConfig = {} as ITableConfig;

  @Output() events: EventEmitter<IEvent> = new EventEmitter();

  @ViewChild('table') table!: any;
  tableId: string;

  dataSource = new MatTableDataSource([]);

  constructor() {
    this.tableId = this.makeId(12);
  }

  ngOnInit(): void {
    this.data.pipe(takeUntil(this.destroyer$)).subscribe(data => this.dataSource.data = data);
  }

  ngAfterViewInit(): void {
    this.table?.contentChanged.pipe(takeUntil(this.destroyer$)).subscribe((payload: any) => {
      this.events.emit({ type: EventTypes.TableContentChanged, payload: payload })
    })
  }

  private makeId(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  get displayedColumns(): string[] {
    return this.headers.map(header => header.name);
  }

  exportTable(): void {
    console.log('export');
    TableUtil.exportTableToExcel(this.tableId);
  }

  ngOnDestroy(): void {
    this.destroyer$.next(null);
    this.destroyer$.unsubscribe();
  }
}
