// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment, ZoneMap } from "./ienvironment";


const apiHost = '';
const apiUrl = '';

const amplifyConfig = {
  'us-east-1': {
    Auth: {
      oauth: {
        domain: 'sso.mss.mrsp.io',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://apollo.mss.mrsp.io',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code',
      },
      region: 'us-east-1',
      userPoolId: 'us-east-1_7VMky1KBI',
      userPoolWebClientId: '7u2eglhst8j3iceor8i1acf2f3'
    },
    API: {
      endpoints: [
        { name: 'search', endpoint: 'https://ddos-api.mss.mrsp.io/v1/widgets/ddos_page/ddos_table/get-data' }
      ]
    }
  },
  'ap-southeast-1': {
    Auth: {
      oauth: {
        domain: 'sso.ap1.mss.mrsp.io',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://apollo.ap1.mss.mrsp.io',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code',
      },
      region: 'ap-southeast-1',
      userPoolId: 'ap-southeast-1_xkHixM1Kl',
      userPoolWebClientId: '7leghc8cf5q44bbluir33ri3ak'
    },
    API: [
      { name: 'search', endpoint: 'https://ddos-api.ap1.mss.lumen.com/v1/widgets/ddos_page/ddos_table/get-data' }
    ]
  },
  'eu-central-1': {
    Auth: {
      oauth: {
        domain: 'sso.eu1.mss.mrsp.io',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://apollo.eu1.mss.mrsp.io',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code',
      },
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_GeJWrMGtg',
      userPoolWebClientId: '2hvldfn2288ngaqsnq933btd4k'
    },
    API: []
  }
};
const zoneEntries = {
  'us-east-1': [
    {
      "key": "api_url",
      "value": "https://ddos-api.mss.mrsp.io",
      "type": "default",
      "enabled": true
    },
    {
      "key": "branch",
      "value": "v1",
      "type": "default",
      "enabled": true
    }
  ],
  'eu-central-1': [
    {
      "key": "api_url",
      "value": "https://api.eu1.mss.lumen.com",
      "type": "default",
      "enabled": true
    },
    {
      "key": "branch",
      "value": "v1",
      "type": "default",
      "enabled": true
    }
  ],
  'ap-southeast-1': [
    {
      "key": "api_url",
      "value": "https://api.ap1.mss.lumen.com",
      "type": "default",
      "enabled": true
    },
    {
      "key": "branch",
      "value": "v1",
      "type": "default",
      "enabled": true
    }
  ]
}

const regions = [
  { label: 'US-DEV', value: 'us-east-1' },
  { label: 'AP-DEV', value: 'ap-southeast-1' },
  { label: 'EU-DEV', value: 'eu-central-1' },
];

export const environment: IEnvironment = {
  production: false,
  enableDebugTools: true,
  logLevel: 'debug',
  apiHost,
  apiUrl,
  baseUrl: 'https://ddos-api.mss.mrsp.io/v1/',
  reputationLookupUrl: 'https://ddos-api.mss.mrsp.io/v1/widgets/ddos_page/ddos_table/get-data',
  amplifyConfig,
  regionDomains: {
    'https://ddos-api.eu1.mss.lumen.com': {
      region: 'eu-central-1'
    },
    'https://ddos-api.ap1.mss.lumen.com': {
      region: 'ap-southeast-1'
    },
    'https://ddos-api.mss.mrsp.io': {
      region: 'us-east-1'
    },
    'https://localhost': {
      region: 'us-east-1'
    },
  },
  zones: new Map(Object.entries(zoneEntries)),
  regions: regions,
  routesRegister: {
    // 'forgotPassword': 'https://sweet.mss.mrsp.io/auth/forgot-password'
    'forgotPassword': 'https://pilot.mss.centurylink.com/auth/forgot-password'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.