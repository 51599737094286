import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "apps/apolo/src/environments/environment";
import { throwError } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class GatewayService {

    public baseUrlVal: string = '';


    constructor() {
        this.baseUrlVal = this.baseUrl();
    }


    public baseUrl() {
        return environment.baseUrl;
    }

}
