import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ColorConfig, IButtonConfig, IDictionary } from '@medusa/ui';
import { IInputConfig } from '@medusa/ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { IpAndDomainService } from '../config/ip-and-domain.service';

const IP_FIELD_REGEXP = `^(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)/(\\d|[1-2]\\d|3[0-2])$`;
export const MAYA_DATE_RANGE_MAP: { [key: string]: any } = {
  'l24h': { dateFrom: '-24h@h', 'dateTo': '@h' },
  'l72h': { dateFrom: '-72h@h', 'dateTo': '@h' },
  'l7d': { dateFrom: '-7d@d', 'dateTo': '@d' },
  'l14d': { dateFrom: '-14d@d', 'dateTo': '@d' },
  'l30d': { dateFrom: '-30d@d', 'dateTo': '@d' },
  'l60d': { dateFrom: '-60d@d', 'dateTo': '@d' },
}

@Component({
  selector: 'medusa-ip-and-domain-reputation-overview',
  templateUrl: './ip-and-domain-reputation-overview.component.html',
  styleUrls: ['./ip-and-domain-reputation-overview.component.scss'],
})
export class IpAndDomainReputationOverviewComponent {
  form: FormGroup;
  config = { display: true, appearance: 'outline' } as IInputConfig;
  searchBtnConfig: IButtonConfig = { color: ColorConfig.primary, type: 'raised' };
  searchControlButtons = [
    { value: 'l24h', friendly: 'Last 24 hours', styles: {} },
    { value: 'l72h', friendly: 'Last 72 hours', styles: {} },
    { value: 'l7d', friendly: 'Last 7 days', styles: {} },
    { value: 'l14d', friendly: 'Last 14 days' },
    { value: 'l30d', friendly: 'Last 30 days' },
    { value: 'l60d', friendly: 'Last 60 days' },
  ];
  selectedStyle = { 'background-color': '#48d597', 'color': 'black', 'font-size': '16px' };
  searchResults$: BehaviorSubject<any> = new BehaviorSubject(null);
  searchResultsError$: Observable<any> | undefined;
  searchLoader$: Observable<any> | undefined;

  tableConfig = {
    style: {} as IDictionary,
    classes: ['mat-elevation-z3']
  }

  searchTableHeaders = [
    { name: 'ISODATE', label: 'ISODATE', extractor: (el: any) => el.ISODATE },
    { name: 'Source Address', label: 'Source Address', extractor: (el: any) => el['Source Address'] },
    { name: 'Destination Address', label: 'Destination Address', extractor: (el: any) => el['Destination Address'] ?? '---' },
    { name: 'Source Score', label: 'Source Score', extractor: (el: any) => el['Source Score'] ?? '---' },
    { name: 'Destination Score', label: 'Destination Score', extractor: (el: any) => el['Destination Score'] ?? '---' },
  ];

  constructor(
    private fb: FormBuilder,
    private searchService: IpAndDomainService
  ) {
    this.form = this.fb.group({
      searchValue: ['192.0.0.0/8', [Validators.required, Validators.pattern(IP_FIELD_REGEXP)]],
      dateRange: ['l7d', [Validators.required]]
    });
  }



  searchbuttonClicked() {
    if (this.form.valid) {
      const { searchValue, dateRange } = this.form.value;
      const searchPayload = { 'IPv4_CIDR': searchValue, ...MAYA_DATE_RANGE_MAP[dateRange], dateType: 'maya' };
      // Make the call here.
      this.searchService.search(searchPayload);
      this.searchResults$ = this.searchService.data$;
      this.searchResultsError$ = this.searchService.error$;
      this.searchLoader$ = this.searchService.loader$;
    }
  }
}
