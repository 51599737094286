import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModuleModule, UiModule } from '@medusa/ui';
import { LoginPageComponent } from './components/login-page/login-page.component';


@NgModule({
  declarations: [
    LoginPageComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModuleModule,
  ],
  exports: [
    LoginPageComponent
  ],
})
export class AuthenticationModule { }