import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { ErrorService } from '../../core/_services/error.service';
import { GatewayService } from '../../core/_services/gateway.service';
import { API, Auth } from 'aws-amplify';

@Injectable({
    providedIn: 'root',
})
export class IpAndDomainService {
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'my-auth-token'
        })
    }
    url = '';
    data$: BehaviorSubject<any> = new BehaviorSubject(null);

    private error$$: BehaviorSubject<any> = new BehaviorSubject(null);
    error$ = this.error$$.asObservable();

    private loader$$: BehaviorSubject<any> = new BehaviorSubject(null);
    loader$ = this.loader$$.asObservable();

    constructor(private http: HttpClient,
        private errorService: ErrorService,
        private gatewayService: GatewayService) { }


    /** Searching a reputation value:  */
    search(payload: any): void {
        this.loader$$.next(true);
        Auth.currentSession().then(
            res => {
                // The currentSession will give us information about the user currently loggedIn and we will be able to
                // extract the Authorization token from it as well to build the header needed by the backend.
                const params = {
                    headers: { Authorization: res.getIdToken().getJwtToken() },
                    body: payload,
                };
                API.put('search', '', params).then(response => {
                    this.error$$.next(null);
                    this.fetchData(response.url)
                }).catch(error => {
                    this.error$$.next(error);
                    this.loader$$.next(false);
                })
            }
        );


    }

    fetchData(url: string): any {
        this.http.get(url).subscribe(data => {
            this.data$.next(data);
            this.error$$.next(null);
            this.loader$$.next(false);
        })
    }

}