import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { ErrorService } from './error.service';

export const DEFAULT_SEARCH_PKG = {
  dateFrom: '-1d@d',
  dateTo: '@d',
  dateType: 'maya',
  IPv4_CIDR: '5.0.0.0/8'
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient, private errorService: ErrorService) { }

  get(url: string, options: any): Observable<any> {
    return this.httpClient.get(url, options).pipe(
      catchError(this.errorService.handleError)
    )
  }

  post(url: string, data: any, options: any) {
    return this.httpClient.post(url, data, options).pipe(
      catchError(this.errorService.handleError)
    )
  }
}
