<header>
  <div class="logo">
    <a [href]="logo.url">
      <img [src]="logo.option" />
    </a>

    <!-- <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="authUser$ | async" class="auth-user">
      <mat-icon>more_vert</mat-icon>
    </button> -->
    <ng-container *ngIf="!!(authUser$ | async)">
      <img ngxGravatar [matMenuTriggerFor]="menu" [email]="(authUser$ | async)?.email || ''" />
    </ng-container>
    <mat-menu #menu="matMenu">
      <button (click)="logout()" mat-menu-item>
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>

  </div>
  <div class="band"></div>
</header>